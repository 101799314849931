import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { graphql } from 'gatsby';
import FormContact from '../components/FormContact/index';
import styled from 'styled-components';
import { CONSTANT } from '../constants';
import Img from 'gatsby-image';
import { down } from 'styled-breakpoints';
import { FormattedMessage } from 'gatsby-plugin-intl';

const StyledHeader = styled.section`
  padding-top: 120px;
  position: relative;
  min-height: 300px;
`;

const BackgroundImage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;

  .gatsby-image-wrapper {
    max-width: 100%;
    max-height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const BackgroundImageGradient = styled.div`
  background: linear-gradient(-135deg, #a137bc 0%, #d83c48 100%);
  background-color: #c6368a;
  background: linear-gradient(-135deg, #c6368a 0%, #673dc2 100%);
  background: linear-gradient(150deg, #124d86 0%, #14204e 100%);
  opacity: 0.9;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 2;
`;

const HeaderContainer = styled.div`
  z-index: 4;
  //position: relative;
  bottom: 140px;

  ${down('md')} {
    bottom: 20px;
  }

  h2 {
    font-size: 50px;
    line-height: 1.05;
    font-weight: 700;
    max-width: 500px;
    color: #889ab4;
    margin-bottom: 0;

    ${down('md')} {
      font-size: 20px;
    }
  }

  h1 {
    color: white;
    font-size: 60px;
    margin-bottom: 0 !important;
    font-weight: 700;

    ${down('md')} {
      font-size: 40px;
      line-height: 1;
    }
  }
`;

const StyledCatalogWrapper = styled.div`
  width: 100%;
  height: auto;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 60px 135px, rgba(0, 0, 0, 0.14) 0px 15px 65px;
  z-index: 2;
  background: white;
  padding: 40px;
  left: 0;
  right: 0;

  object {
    width: 100%;
    height: 1000px;
  }
`;

const StyledCatalogContainer = styled.div`
  left: 0;
  z-index: 6;
  bottom: 0;
  width: 100%;
  margin-top: -100px;
  position: relative;
`;

const StyledContact = styled.section`
  margin-top: 20px;

  ${down('md')} {
    margin-top: 20px;
  }
`;

const ContactDetail = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;

  i {
    color: #666666;
    margin-right: 10px;
    font-weight: 400;
  }

  a {
    color: #666666;
    font-size: 16px;
    font-weight: 400;
    text-decoration: underline;

    &[target='_blank'] {
      text-decoration: none;
    }

    ${down('md')} {
      &[target='_blank'] {
        text-decoration: underline;
      }
    }
  }
`;

const DownloadWrapper = styled.div`
  display: flex;
  padding-bottom: 20px;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
`;

const Catalog = ({ data }) => {
  const [edge] = data.allContentfulPage.edges;
  const { seoDescription, seoTitle } = edge.node;
  const {
    allContentfulAsset: {
      nodes: [file]
    }
  } = data;
  const currentYear = new Date().getFullYear();
  const sizeBInMB = (file.file.details.size / (1024 * 1024)).toFixed(2);

  return (
    <Layout>
      <SEO title={seoTitle} lang={'es'} description={seoDescription} />

      <StyledHeader className={'height-50'}>
        <BackgroundImageGradient />
        <BackgroundImage>
          {/*<Img fluid={image.node.childImageSharp.fluid} alt="Mapa ALM Print" />*/}
        </BackgroundImage>

        <HeaderContainer className="pos-bottom pos-absolute container">
          <h2>Nuestros servicios</h2>
          <h1>Catálogo {currentYear}</h1>
        </HeaderContainer>
      </StyledHeader>

      <StyledCatalogContainer className="container hidden-xs">
        <StyledCatalogWrapper>
          <DownloadWrapper>
            <div>
              <a href={file.file.url} target="_blank" className="btn btn--sm type--uppercase">
                <span className="btn__text">
                  <i
                    className="icon-Download-fromCloud"
                    style={{ marginRight: '10px', fontSize: '12px', verticalAlign: 'middle' }}
                  />
                  Descargar catálogo
                </span>
              </a>
            </div>
            <div>Tamaño: {sizeBInMB}MB</div>
          </DownloadWrapper>

          <object data={file.file.url} type="application/pdf">
            <embed src={file.file.url} type="application/pdf" />
          </object>
        </StyledCatalogWrapper>
      </StyledCatalogContainer>

      <StyledContact>
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-6">
              <div className="row mx-0 switchable__text flex-column">
                <div className="lead">
                  <ContactDetail>
                    <i className="icon-Email" />
                    <a href="mailto:info@almprint.es">info@almprint.es</a>
                  </ContactDetail>
                  <ContactDetail>
                    <i className="icon-Smartphone-4" />
                    <a href="tel:918729809">918 72 98 09</a>
                  </ContactDetail>
                  <ContactDetail>
                    <i className="icon-Map-Marker2" />
                    <a href={CONSTANT.BUSINESS_GOOGLE_MAP_URL} target="_blank">
                      <p>Calle Hermanos Lumiere. Polígono El Cañal, 5</p>
                      Arganda del Rey, Madrid
                    </a>
                  </ContactDetail>
                </div>
                <p className="lead">
                  Llámenos o visítenos en cualquier momento, nos esforzamos por responder todas las
                  consultas en un plazo máximo 24 horas.
                </p>
                <p className="lead">Estamos abiertos desde las 9:00 a 14:00 - 15:30 a 18:00</p>
                <hr className="short" />
              </div>
            </div>

            <div className="col-md-5">
              <div className="visible-xs">
                <FormContact />
              </div>
            </div>
          </div>
        </div>
      </StyledContact>
    </Layout>
  );
};

export const catalogQuery = graphql`
  query Catalog($slug: String!) {
    allContentfulPage(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          seoTitle
          seoDescription
        }
      }
    }

    site {
      siteMetadata {
        fullAddress
      }
    }

    allContentfulAsset(filter: { title: { eq: "catalogo" } }) {
      nodes {
        title
        file {
          url
          details {
            size
          }
        }
      }
    }
  }
`;

export default Catalog;
